<template>

  <v-container>
    <v-row>
      <v-col>

      <v-card
      class="mx-auto"
      max-width="350"
      min-height="200"
      elevation="4"
      outlined
      >
<div id="capture">
      <div :style="cssProps">
        <div class="imageContainer">
          <div class="borderContainer"
            :style="{ border: borderControl }"
          >
            <div class="divTextControl"
            :style="{ color: qColor, fontFamily: qFamily}"
            >
            <p
            :style="{ fontSize: qSize + 'px', textAlign: qAlign}"
            >
            {{ quote }}
            </p>
            <div
              :style="{ fontSize: aSize + 'px', textAlign: aAlign, marginTop: '5px'}"
            >
            {{ author}}
            </div>
            </div>
          </div>
        </div>
      </div>
      <div align='center'>
        <div class="imgCred"> image by: <a :href="imageLink" target=_blank class='bLink'>{{imageCredit}}</a></div>
      </div>
      <div align='center'>
        {{rfPrint}}
      </div>
    </div>

      <v-card-actions>

        <v-btn
          text
          @click="getQuote"
          outlined
          color="primary"
        >
          <v-icon>
            mdi-refresh
          </v-icon>
          &nbsp;New Quote
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          text
          @click="getBackgroundImage"
          outlined
          color="primary"
        >
        <v-icon>
          mdi-refresh
        </v-icon>
        &nbsp;New Image
        </v-btn>
      </v-card-actions>


      <v-card-actions>

        <div style="width: 30%;">
          <v-select
            class="ac40"
            v-model="quoteLoc"
            :items="locations"
            label="Quote Location"
            dense
            outlined
            color="primary"
            @input="cLocation"
            >
          </v-select>
        </div>

                <v-spacer></v-spacer>

                <div style="width: 30%;">
                  <v-select
                    class="ac40"
                    v-model="bcBorder"
                    :items="borders"
                    label="Border Style"
                    dense
                    outlined
                  ></v-select>
              </div>

                <v-spacer></v-spacer>

                      <div style="width: 30%;">
                      <v-select
                        class="ac40"
                        v-model="iHeight"
                        :items="sizes4"
                        label="Image Height"
                        dense
                        outlined
                      ></v-select>
                    </div>

      </v-card-actions>

      <v-card-actions>

        <div style="width: 30%;">
            <v-select
              class="ac40"
              v-model="qFamily"
              :items="fonts"
              label="Font Type"
              dense
              outlined
            >
            <template #item="{item}">
              <span :style="{fontFamily: item}">{{item}}</span>
            </template></v-select>
        </div>

        <v-spacer></v-spacer>

      <div style="width: 30%;">
          <v-select
            class="ac40"
            v-model="qSize"
            :items="sizes"
            label="Font Size"
            dense
            outlined
          ></v-select>
      </div>

        <v-spacer></v-spacer>

      <div style="width: 30%;">
        <v-select
          class="ac40"
          v-model="qColor"
          :items="colors"
          label="Font Color"
          dense
          outlined
          color="primary"
          @input="cFont"
          >
        </v-select>
      </div>

      </v-card-actions>

      <v-card-actions>

      <div class="outerContainer">

        <div
        class="ac40"
        >
        <v-btn
          icon
          @click="decreaseOpacity"
          :disabled="!butMinus"
          color="primary"
        >
          <v-icon>
            mdi-minus
          </v-icon>
        </v-btn>

        <span
        class="primary--text"
        >
        Image Opacity {{showBO}}%
        </span>

        <v-btn
          icon
          @click="increaseOpacity"
          :disabled="!butPlus"
          color="primary"
        >
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        </div>

</div>

      </v-card-actions>


  <v-card-actions>
    <v-btn
      icon
      color="primary"
      disabled
    >
    <v-icon>
      mdi-magnify
    </v-icon>
    </v-btn>

    <v-btn
      icon
      color="primary"
      disabled
    >
    <v-icon>
      mdi-pencil
    </v-icon>
    </v-btn>

    <v-btn
      icon
      color="primary"
      disabled
    >
    <v-icon>
      mdi-content-save-outline
    </v-icon>
    </v-btn>

    <v-spacer></v-spacer>


    <div v-if="checkMobile">

      <v-btn
        icon
        color="primary"
        @click="shareViaWebShare()"
      >
      <v-icon>
        mdi-share-variant
      </v-icon>
      </v-btn>

    </div>

    <div v-else>

      <v-btn
        icon
        color="primary"
        @click="shareViaWebShare2()"
      >
      <v-icon>
        mdi-share-variant
      </v-icon>
      </v-btn>

    </div>


  </v-card-actions>


      </v-card>

  </v-col>
</v-row>

<v-row>
  <v-col>
    <v-card
    class="mx-auto"
    max-width="350"
      >

      <v-card-text>
        <div class="disc">
          quotes provided by api.quotable.io<br>
          images provided by picsum.photos via unsplash.com
        </div>

      </v-card-text>

    </v-card>
  </v-col>
</v-row>

<v-row>
  <v-col>
    <v-card
    class="mx-auto"
    max-width="350"
      >

      <v-card-text>
        <div class="outerContainer">
Visit our companion site at <a href="https://www.jokedispenser.com" target="_blank" class='bLink'>www.jokedispenser.com</a>
        </div>

      </v-card-text>

    </v-card>
  </v-col>
</v-row>
</v-container>

</template>

<script>
import html2canvas from 'html2canvas'

export default {
  name: 'html5Relevant',
  data () {
    return {
      imageCredit: '',
      imageLink: '',
      rfPrint: '',
      bLink: 'font',
      linkStatus: true,
      iHeight: '3',
      quoteLength: 0,
      mControl: false,
      butPlus: false,
      butMinus: true,
      backgroundImage: '',
      backgroundOpacity: '1',
      btsColor: 'black',
      bRadius: '5',
      showBO: '100',
      bcWidth: '5',
      bcBorder: 'none',
      bcColor: 'white',
      qColor: 'white',
      qSize: '20',
      qFamily: 'itim',
      qAlign: 'center',
      qLeft: 50,
      qTop: 50,
      quoteLoc: 'middle',
      aAlign: 'left',
      imageID: '',
      info: [],
      quote: '',
      author: '',
      locations: ['top', 'middle', 'bottom'],
      fonts: ['bebas neue', 'bree serif', 'caveat', 'fjalla one', 'itim', 'lobster', 'lora', 'merienda', 'noto serif', 'permanent marker', 'righteous', 'roboto', 'satisfy', 'secular one', 'shadows into light', 'unbounded'],
      colors: ['white', 'black', 'red', 'yellow', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'teal', 'indigo', 'lime', 'grey', 'brown'],
      colorsL: ['white', 'pink', 'red', 'cyan', 'lime', 'yellow', 'orange'],
      colorsD: ['black', 'purple', 'indigo', 'blue', 'teal', 'green', 'grey', 'brown'],
      sizes: ['12', '14', '16', '18','20', '22', '24', '26', '28', '30', '32'],
      sizes2: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      sizes3: ['0', '2', '5', '8', '12'],
      sizes4: ['1', '2', '3', '4'],
      borders: ['dashed', 'dotted', 'double', 'groove', 'inset', 'none', 'outset', 'ridge', 'solid'],
      bcLinks: ['font', 'shadow', 'none']
    }
  },
  beforeCreate() {

},
  created () {
    this.getQuote()
    this.getBackgroundImage()
  },

  beforeMount() {

},
  mounted() {

},
  beforeUpdate() {

  },
  updated() {

  },
  computed: {
    checkMobile() {
      var ua = navigator.userAgent
      var isMobileV = /Android|webOS|iPhone|iPad|iPod/i.test(ua)
      return isMobileV
    },
    webShareApiSupported() {
      return navigator.share
    },
    backgroundTextShadow: function(){
      return '1px 1px 5px ' + this.btsColor
    },
    borderControl: function () {
      return this.bcWidth + 'px ' + this.bcBorder + ' ' + this.bcColor
    },
    borderRadius: function () {
      return this.bRadius + 'px '
    },
    quoteLeft: function () {
      return this.qLeft + '%'
    },
    quoteTop: function () {
      return this.qTop + '%'
    },
    quoteLeftT: function () {
      return '-' + this.qLeft + '%'
    },
    quoteTopT: function () {
      return '-' + this.qTop + '%'
    },
    imageHeight: function () {

      if (this.iHeight == '1') return '200px'
      if (this.iHeight == '2') return '250px'
      if (this.iHeight == '3') return '300px'
      if (this.iHeight == '4') return '350px'
      return "300px"
    },
    aSize: function () {
    return this.qSize
  },
    cssProps() {
      return {
      '--background-image': this.backgroundImage,
      '--background-opacity': this.backgroundOpacity,
      '--background-text-shadow': this.backgroundTextShadow,
      '--border-radius': this.borderRadius,
      '--quote-left': this.quoteLeft,
      '--quote-top': this.quoteTop,
      '--quote-left-t': this.quoteLeftT,
      '--quote-top-t': this.quoteTopT,
      '--image-height': this.imageHeight
      }
    }
  },
  methods: {
    async getQuote() {

      const res = await fetch('https://api.quotable.io/random')
      const data = await res.json()
//      console.log('data: ', data)
      this.quote = data.content
      this.author = data.author
      this.quoteLength = data.length
      this.checkLength()
    },
    checkLink() {
      if (this.bLink == 'none') {
        this.linkStatus = false
      }
      else if (this.bLink == 'shadow'){
        this.linkStatus = true
        this.bcColor = this.btsColor
      }
      else if (this.bLink == 'font'){
        this.linkStatus = true
        this.bcColor = this.qColor
      }
    },
    cLocation() {
      switch(this.quoteLoc) {
        case 'top':
        this.qLeft = 0
        this.qTop = 0
          break;
        case 'middle':
        this.qLeft = 50
        this.qTop = 50
          break;
        case 'bottom':
        this.qLeft = 100
        this.qTop = 100
          break;
//        default:
          // code block
      }
    },
    cFont() {
      if (this.bLink == 'font'){
        this.linkStatus = true
        this.bcColor = this.qColor
      }
      if (this.colorsL.includes(this.qColor)){
        this.btsColor = 'black'
      } else {
          this.btsColor = 'white'
      }
    },
    sFont() {
      if (this.bLink == 'shadow'){
        this.linkStatus = true
        this.bcColor = this.btsColor
      }
    },
    checkLength() {
      if (this.quoteLength >= 375) {
        this.qSize = '16'
      }
      else if (this.quoteLength >= 275) {
        this.qSize = '18'
      }
      else if (this.quoteLength >= 200) {
        this.qSize = '20'
      }
      else if (this.quoteLength >= 150) {
        this.qSize = '22'
      }
      else {
        this.qSize = '24'
      }
    },
    async getBackgroundImage() {
      const res = await fetch('https://picsum.photos/400')
      var pos1 = res.url.split('/', 4).join('/').length
      var pos2 = res.url.split('/', 5).join('/').length
      var imageId = res.url.slice(pos1 + 1, pos2)
      this.backgroundImage = 'url(' + res.url + ')'
      this.getInfo(imageId)
    },
    async getInfo(imID) {
      const res = await fetch('https://picsum.photos/id/' + imID + '/info')
      const data = await res.json()
//      console.log('data: ', data)
      this.imageCredit = data.author
      this.imageLink = data.url
    },
    decreaseOpacity() {
      var tOpacity = (parseFloat(this.backgroundOpacity)  - .05);
      var tOP2 = Math.round((tOpacity + Number.EPSILON) * 100) / 100
      var tSBO = Math.round(tOP2 * 100)
      if (tSBO < 5) {
        this.butMinus = false;
      }
      this.butPlus = true;
      this.backgroundOpacity = tOP2
      this.showBO = tSBO
    },
    increaseOpacity() {
      var tOpacity = (parseFloat(this.backgroundOpacity)  + .05);
      var tOP2 = Math.round((tOpacity + Number.EPSILON) * 100) / 100
      var tSBO = Math.round(tOP2 * 100)
      if (tSBO > 95) {
        this.butPlus = false;
      }
      this.butMinus = true;
      this.backgroundOpacity = tOP2
      this.showBO = tSBO
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    shareViaWebShare() {
      this.rfPrint = 'www.quotedispenser.com'
      this.delay(100).then(() => this.mobileShare())
    },
    shareViaWebShare2() {
      this.rfPrint = 'www.quotedispenser.com'
      this.delay(100).then(() => this.deskShare())
    },
    mobileShare() {
      var quoteLoc = document.getElementById('capture')

      html2canvas(quoteLoc, {logging: true, letterRendering: true, allowTaint: false, useCORS: true, backgroundColor: 'white' }
      ).then(canvas => {
        //this.saveAs(canvas.toDataURL('image/jpg'), 'FastFest.jpg');
        this.tmpCanvas = canvas
        var tCanvas = canvas.toDataURL()
        var blob = this.dataURItoBlob(tCanvas);
        const file = new File([blob], 'fileName.png', { type: blob.type });
        navigator
        .share({
          files: [file],
        })
        })
  //      .catch((error) => {
  //      console.log("ERROR", error)
  //      })
      this.delay(100).then(() => this.rfPrint = '')
      },
      deskShare() {
      var quoteLoc = document.getElementById('capture')
    //    var jokeNew = jokeOrig + '<div align=center width=100%>www.jokedispenser.com</div>'

        html2canvas(quoteLoc, {logging: true, letterRendering: true, allowTaint: false, useCORS: true, backgroundColor: 'white' }
      ).then(canvas => {
        //this.saveAs(canvas.toDataURL('image/jpg'), 'FastFest.jpg');
        canvas.toBlob(function (blob) {

            let data = [new window.ClipboardItem({ [blob.type]: blob })];

            if (navigator.clipboard) {
                navigator.clipboard.write(data).then(function () {
                    alert('image has been saved to your clipboard')
                }, function (err) {
                    console.log('error', err)
                });
            } else {
            //    console.log('Browser do not support Clipboard API')
            }

        }, 'image/png')
        })
  //      .catch((error) => {
  //      console.log("ERROR", error)
  //      })
  //    pZ.style.display='none'
    this.delay(100).then(() => this.rfPrint = '')
      },
    dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}
  }
}
</script>
<style scoped>

  .imageContainer {
    position: relative;
    height: var(--image-height);
    min-width: 300px;
    max-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-color: black;
    border-style: solid;
  }

  .imageContainer::before {
    content: "";
    background-image: var(--background-image);
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: var(--background-opacity);
}

  .borderContainer {
    height: 95%;
    width: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    border-radius: var(--border-radius);
  }

  .divTextControl {
    position: relative;
    left: var(--quote-left);
    top: var(--quote-top);
    transform: translate(var(--quote-left-t), var(--quote-top-t));
    font-weight: 900;
    text-shadow: var(--background-text-shadow);
  }

  .ac40 {
  height: 40px;
  }

  .ac40a {
  height: 40px;
  font-size: 18px;
  }

  .outerContainer {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .parent {
    width: var(--width-height);
    height: var(--width-height);
    position: relative;
    top: 0;
    left: 0;
    border-style: solid;
    border-width: thin;
    display: inline-block;
    margin: 0 auto;
  }

  a.bLink:link, a.bLink:visited {
    color: black;
    text-decoration: none;
  }

  a.bLink:hover, a.bLink:active {
    color: blue;
    text-decoration: underline;
  }

  .imgCred {
  padding-top: 10px;
  font-size: 14px;
  }
</style>
