import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.purple.darken1,
        secondary: colors.cyan.accent2
      },
      dark: {

      },
    },
  },
}

export default new Vuetify(opts)
