<template>
  <v-app>
    <div v-if="updateExists">
      <v-btn
        block
        @click="refreshApp"
      >
        Update App
      </v-btn>
    </div>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer
      app
      class="text-center"
    >
        <v-icon
        small
        >
        mdi-copyright
      </v-icon>
      {{ new Date().getFullYear() }} &nbsp; QuoteDispenser.com

      <v-spacer></v-spacer>
      v1.8
    </v-footer>
  </v-app>
</template>

<script>
import update from './mixins/update'

export default {
  data() {
    return {

    };
  },
  mixins: [update]
};
</script>

<style>

@font-face {
  font-family: "Bebas Neue";
  src: local("Bebas Neue"),
   url(./assets/fonts/BebasNeue-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Bree Serif";
  src: local("Bree Serif"),
   url(./assets/fonts/BreeSerif-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"),
   url(./assets/fonts/Caveat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Fjalla One";
  src: local("Fjalla One"),
   url(./assets/fonts/FjallaOne-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Itim";
  src: local("Itim"),
   url(./assets/fonts/Itim-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lobster";
  src: local("Lobster"),
   url(./assets/fonts/Lobster-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lora";
  src: local("Lora"),
   url(./assets/fonts/Lora-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Merienda";
  src: local("Merienda"),
   url(./assets/fonts/Merienda-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Noto Serif";
  src: local("Noto Serif"),
   url(./assets/fonts/NotoSerif-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Permanent Marker";
  src: local("Permanent Marker"),
   url(./assets/fonts/PermanentMarker-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Righteous";
  src: local("Righteous"),
   url(./assets/fonts/Righteous-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Satisfy";
  src: local("Satisfy"),
   url(./assets/fonts/Satisfy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Secular One";
  src: local("Secular One"),
   url(./assets/fonts/SecularOne-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Shadows Into Light";
  src: local("Shadows Into Light"),
   url(./assets/fonts/ShadowsIntoLight-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Unbounded";
  src: local("Unbounded"),
   url(./assets/fonts/Unbounded-Regular.ttf) format("truetype");
}
</style>
